const messages = {
  copy: {
    first: 'HP blok',
    last: 'bol skopírovaný'
  },
  list: {
    id: 'Id',
    type: 'Typ',
    title: 'Titulok',
    identifier: 'Identifikátor',
    number_of_items: 'Počet položiek',
    external_fallback: 'Externý záložný plán',
    internal_fallback: 'Interný záložný plán',
    fallback_article_types: 'Záložné typy článkov',
    fallback_article_flags: 'Záložné značky článkov',
    hidden: 'Skrytý',
    position: 'Pozícia',
    created_at: 'Vytvorené',
    modified_at: 'Zmenené',
    created_by: 'Vytvoril',
    fallback_settings: 'Záložné nastavenia'
  },
  filter: {
    site: 'Stránka',
    type: 'Typ',
    title: 'Titulok',
    identifier: 'Identifikátor',
    id: 'Id',
    search: 'Hľadať'
  },
  id: 'Id',
  hidden: 'Skrytý',
  type: 'Typ',
  title: 'Titulok',
  identifier: 'Identifikátor',
  site: 'Stránka',
  category: 'Kategória',
  number_of_items: 'Počet položiek',
  position: 'Pozícia',
  daysLimit: 'Limit dní pre dopyt',
  display_position: 'Pozícia zobrazenia',
  disabled_positions: 'Zakázané pozície',
  fallback_positions: 'Záložné pozície',
  external_fallback: 'Povoliť externý záložný plán',
  internal_fallback: 'Povoliť interný záložný plán',
  fallback_site: 'Záložná stránka',
  fallback_rubrics: 'Záložné rubriky',
  fallback_tags: 'Záložné tagy',
  fallback_article_types: 'Typy článkov (bude použité logické OR)',
  fallback_article_flags: 'Značky článkov (bude použité logické OR)',
  fallback_article_settings: 'Nastavenia článkov',
  fallback_categories: 'Záložné kategórie',
  content_block_type_value: {
    article: 'Článok',
    video_article: 'Video článok',
    video: 'Video'
  },
  main_settings: 'Hlavné nastavenia',
  fallback_settings: 'Záložné nastavenia',
  gallery: 'Galéria',
  pr: 'Len PR články'
}

export default messages
